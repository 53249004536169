<template>
  <el-container style="border: 1px solid #eee" class="main">
    <el-aside width="300px">
      <el-input v-model="filterText" placeholder="搜索术语"></el-input>
      <el-tree
        class="filter-tree"
        node-key="id"
        :data="treeData"
        :props="defaultProps"
        :filter-node-method="filterNode"
        @node-click="getTermDetail"
        ref="tree"
        :highlight-current="true"
      >
      </el-tree>
    </el-aside>
    <el-container>
      <el-main>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="attributes.name" label="名字">
          </el-table-column>
          <el-table-column prop="attributes.description" label="描述">
          </el-table-column>
          <el-table-column prop="typeName" label="类型"> </el-table-column>
          <el-table-column label="分类">
            <template slot-scope="scope">
              <el-tag
                v-for="item in scope.row.classificationNames"
                :key="item"
                style="margin-right: 5px"
              >
                {{ item }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="血缘">
            <template slot-scope="scope">
              <el-button type="info" round @click="viewBloodline(scope.row)"
                >查看血缘</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="请求">
            <template slot-scope="scope">
              <el-button
                type="info"
                round
                @click="doCopy(scope.row.attributes.name, scope.row.typeName)"
                >生成请求链接</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <div v-show="visible" :class="['my-message', 'my-message-' + type]">
      <p>{{ message }}</p>
    </div>
    <bloodline ref="bloodline"></bloodline>
  </el-container>
</template>

<script>
import axios from "axios";
import qs from "qs";
import bloodline from "./bloodline.vue";
export default {
  components: {
    bloodline,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      filterText: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      visible: false,
      center: false,
      type: undefined,
      message: "",
    };
  },
  created() {},
  mounted() {
    this.instance = axios.create();
    this.instance({
      url: "/metadata/j_spring_security_check",
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
      data: qs.stringify({
        j_username: "admin",
        j_password: "admin",
      }),
    }).then(() => {
      this.instance({
        url: "/metadata/api/atlas/v2/glossary",
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }).then(({ data }) => {
        data.forEach((element) => {
          var node = {};
          var children = [];
          node["id"] = element.guid;
          node["label"] = element.name;

          if (element.hasOwnProperty("terms")) {
            element.terms.forEach((item) => {
              var childrenNode = {};
              childrenNode["id"] = item.termGuid;
              childrenNode["label"] = item.displayText;
              children.push(childrenNode);
            });
            node["children"] = children;
          }
          this.treeData.push(node);
          // console.log(this.treeData);
        });
      });
    });
    // const instance = axios.create();
  },
  methods: {
    // 过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 获取术语具体信息
    getTermDetail(data, node) {
      this.$refs.tree.setCurrentKey(data.id);
      if (!data.children) {
        const termName = data.label + "@" + node.parent.data.label;
        axios({
          url: "/metadata/api/atlas/v2/search/basic",
          method: "post",
          withCredentials: true,
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            excludeDeletedEntities: true,
            includeClassificationAttributes: true,
            includeSubClassifications: true,
            includeSubTypes: true,
            termName,
          }),
        }).then(({ data }) => {
          console.log("res", data);
          this.tableData = [];
          if (data.entities) {
            this.tableData = data.entities;
          }
        });
      }
    },
    // 复制到剪切板
    doCopy(name, type) {
      let value;
      console.log("type :>> ", type);
      if (type == "hbase_table") {
        value = `http://${this.$store.state.backendIp}:8081/hbase/getAllMpInfoByHbaseTablename/?hbasetablename=${name}`;
      } else {
        // this.$message({
        //   type: "info",
        //   message: "暂时不支持",
        //   during: 3000
        // })
        this.message = "暂时不支持";
        this.type = "info";
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, 3000);
        return;
      }
      this.$copyText(value)
        .then(() => {
          // this.$message.success("内容已复制到剪切板");
          this.message = "内容已复制到剪切板";
          this.type = "success";
          this.visible = true;

          setTimeout(() => {
            this.visible = false;
          }, 3000);
        })
        .catch(() => {
          // this.$message.error("抱歉，复制失败");
          this.message = "抱歉，复制失败";
          this.type = "error";
          this.visible = true;
          setTimeout(() => {
            this.visible = false;
          }, 3000);
        });
    },
    viewBloodline({guid}) {
      axios({
        url: `/metadata/api/atlas/v2/lineage/${guid}`,
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }).then(({ data }) => {
        this.$refs["bloodline"].init(data);
      });
    },
  },
};
</script>

<style scoped>
.main
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #7ec7ff;
}
.main >>> .el-tree-node__label {
  font-size: 20px;
  height: auto;
}
.main >>> .el-tree-node__content {
  height: 40px;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  line-height: 100px;
  color: #333;
  height: 900px;
}
/* .el-tree-node.is-current > .el-tree-node__content {
  background-color: #38bb9b !important;
} */
/* .el-tree-node:focus > .el-tree-node__content {
  background-color: rgb(158, 213, 250) !important;
} */
p {
  margin: 0;
  padding: 0;
}
.my-message {
  min-width: 300px;
  min-height: 50px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  background-color: #edf2fc;
  transition: opacity 0.3s, transform 0.4s, top 0.4s;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
}
.my-message-info {
  color: #909399;
}
.my-message-success {
  background: #f2f9ec;
  color: #67c23a;
  border-color: #e4f2da;
}
.my-message-warning {
  background: #fcf6ed;
  color: #e6a23c;
  border-color: #f8ecda;
}
.my-message-error {
  background: #fcf0f0;
  color: #f56c6c;
  border-color: #f9e3e2;
}
</style>
